import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Orddar from '../../Icons/Orddar.svg';  // Import the SVG file
import '../../css/main.css'
import Dining from '../../Icons/Dining.svg';
import { Link } from 'react-router-dom';


function Head() {
    return (
        <div>

            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="/">  <img src={Orddar} alt="Orddar Logo" className='navImg' />
                        <img src={Dining} className='navImg2' alt="Dining Logo" />  </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Link to={'/'} style={{ color: " #37373f", marginRight: '20px' }}>HOME</Link>
                            <Link to={'/about'} style={{ color: " #37373f", marginRight: '20px' }}>STORY</Link>
                            <Link to={'/policies'} style={{ color: " #37373f", marginRight: '20px' }}>POLICIES</Link>
                            <Link to={'/Contact'} style={{ color: " #37373f", marginRight: '20px' }}> CONTACT</Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>


        </div>
    )
}

export default Head